<template>
  <div>
    <div
      v-for="model in filteredModel"
      :key="model"
      class="col-12 row permission-wrapper"
    >
      <span class="permissions-list-title form-control-label col-12 mb-2 mt-3">
        {{ $t(`COMMON.${model}`) }}
      </span>
      <div
        v-for="permission in permissions.filter((perm) =>
          perm.key.includes(model)
        )"
        :key="permission.key"
        class="permission-item col-6"
      >
        <icon-check :checked="hasPermission(permission)" />
        {{ $t(`PERMISSIONS.${permission.key}`) }}
      </div>
    </div>
  </div>
</template>
<script>
import IconCheck from "@/components/IconCheck.vue";
import models from "@/constants/models";
import {
  SCOPE_ADMIN,
  SCOPE_ORGANIZATION,
  SCOPE_RESELLER,
} from "../constants/permissions";

export default {
  name: "role-permissions",

  components: { IconCheck },

  props: {
    role: {
      type: Object,
      default: () => ({}),
    },
    scope: {
      type: Number,
      required: false,
    },
  },

  data() {
    return { models: models, permissions: [] };
  },

  computed: {
    filteredModel: function () {
      return Object.values(this.models).filter((item) =>
        this.modelHasPermissions(item)
      );
    },
  },

  created() {
    this.getPermissions();
  },

  methods: {
    hasPermission(permission) {
      if (!this.role.permissions) {
        return false;
      }
      return !!this.role.permissions.find((perm) => {
        return perm.id === permission.id;
      });
    },
    async getPermissions() {
      try {
        await this.$store.dispatch("permissions/list", {
          sort: "name",
          page: {
            number: 1,
            size: 999999,
          },
        });
        const perms = await this.$store.getters["permissions/list"];
        let scope = this.scope;
        if (!scope) {
          scope = this.calculateScope();
        }
        this.permissions = perms.filter((item) => item.scope >= scope);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    modelHasPermissions(model) {
      return (
        this.permissions.filter((perm) => perm.key.includes(model)).length > 0
      );
    },

    calculateScope() {
      if (this.role.organization) {
        return SCOPE_ORGANIZATION;
      }
      if (this.role.reseller) {
        return SCOPE_RESELLER;
      }
      return SCOPE_ADMIN;
    },
  },

  mounted() {},

  watch: {
    role(role) {},
  },
};
</script>
